import React from 'react';
import styles from '../styles/Game.module.scss';
import GameWindow from '../components/GameWindow';

const GamePage = () => {
  return (
    <div className={styles.gamePage}>
      <GameWindow />
    </div>
  );
};

export default GamePage;
