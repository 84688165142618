import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/PlayButton.module.scss';

const PlayButton = () => {
  const navigate = useNavigate();
  const [targetSpeed, setTargetSpeed] = useState(0.004); // Vitesse cible initiale (en radians par frame)
  const currentSpeedRef = useRef(0.004); // Vitesse actuelle utilisée pour la rotation
  const rotationRef = useRef(0); // Angle de rotation actuel
  const rotatingBorderRef = useRef(null); // Référence à l'élément de la bordure rotative
  const animationFrameRef = useRef(null);

  const Click = () => {
    navigate('/game');
  };

  const handleMouseEnter = () => {
    setTargetSpeed(0.03); // Augmente la vitesse au survol
  };

  const handleMouseLeave = () => {
    setTargetSpeed(0.004); // Revient à la vitesse normale
  };

  useEffect(() => {
    const rotate = () => {
      // Ajuste progressivement la vitesse actuelle vers la vitesse cible
      currentSpeedRef.current += (targetSpeed - currentSpeedRef.current) * 0.05;

      rotationRef.current += currentSpeedRef.current;
      if (rotatingBorderRef.current) {
        rotatingBorderRef.current.style.transform = `rotate(${rotationRef.current}rad)`;
      }
      animationFrameRef.current = requestAnimationFrame(rotate);
    };
    animationFrameRef.current = requestAnimationFrame(rotate);

    return () => {
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [targetSpeed]);

  return (
    <div
      className={styles.buttonWrapper}
      onClick={Click}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.rotatingBorder} ref={rotatingBorderRef}></div>
      <div className={styles.circleContent}>
        <div className={styles.triangle}></div>
      </div>
    </div>
  );
};

export default PlayButton;
