import React, { useEffect, useRef, useState } from 'react';
import Matter from 'matter-js';
import * as Tone from 'tone';
import styles from '../styles/GameWindow.module.scss';
import GameMenu from './GameMenu';

const GameWindow = () => {
  const sceneRef = useRef(null);
  const engineRef = useRef(Matter.Engine.create({ enableSleeping: false }));
  const runnerRef = useRef(null);
  const emitterRef = useRef(null);
  const synthRef = useRef(null);

  const renderRef = useRef(null); // Référence pour le moteur de rendu
  const previewLineRef = useRef(null); // Référence pour la ligne de prévisualisation

  const [isDragging, setIsDragging] = useState(false);
  const [isDrawingLine, setIsDrawingLine] = useState(false);
  const [startPoint, setStartPoint] = useState(null);

  // États pour l'outil de suppression et le rechargement
  const [isDeleteToolActive, setIsDeleteToolActive] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [highlightedLine, setHighlightedLine] = useState(null);

  const MAX_SPEED = 10;

  useEffect(() => {
    const engine = engineRef.current;
    engine.gravity.y = 1;

    engine.positionIterations = 20;
    engine.velocityIterations = 20;
    engine.constraintIterations = 20;
    engine.collisionIterations = 20;

    const runner = Matter.Runner.create({
      isFixed: false,
      delta: 1000 / 120,
    });
    runnerRef.current = runner;
    Matter.Runner.run(runner, engine);

    const render = Matter.Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: '#000000',
        autoClear: true, // Assure que le canvas est effacé à chaque frame
      },
    });
    Matter.Render.run(render);
    renderRef.current = render; // Stocker le moteur de rendu dans la référence

    const emitter = Matter.Bodies.circle(
      window.innerWidth / 2,
      window.innerHeight / 2,
      6,
      {
        isStatic: true,
        render: { fillStyle: '#00ff00' },
        collisionFilter: { category: 0x0001 },
      }
    );
    emitterRef.current = emitter;
    Matter.World.add(engine.world, [emitter]);

    const emissionInterval = setInterval(() => {
      const ball = Matter.Bodies.circle(
        emitter.position.x,
        emitter.position.y,
        3,
        {
          label: 'Ball',
          restitution: 1.6,
          friction: 0,
          frictionAir: 0.001,
          render: { fillStyle: '#ffffff' },
          collisionFilter: { category: 0x0002, mask: 0x0002 },
        }
      );

      Matter.World.add(engine.world, ball);
    }, 500);

    Matter.Events.on(engine, 'afterUpdate', () => {
      engine.world.bodies.forEach((body) => {
        if (
          body.position.y > window.innerHeight + 50 ||
          body.position.x < -50 ||
          body.position.x > window.innerWidth + 50
        ) {
          Matter.World.remove(engine.world, body);
        }

        if (body.label === 'Ball') {
          const velocity = body.velocity;
          const speed = Math.sqrt(velocity.x ** 2 + velocity.y ** 2);

          if (speed > MAX_SPEED) {
            Matter.Body.setVelocity(body, {
              x: (velocity.x / speed) * MAX_SPEED,
              y: (velocity.y / speed) * MAX_SPEED,
            });
          }
        }
      });
    });

    // Créer un échantillonneur avec des sons de piano
    synthRef.current = new Tone.Sampler({
      urls: {
        A1: 'A1.mp3',
        C2: 'C2.mp3',
        'D#2': 'Ds2.mp3',
        'F#2': 'Fs2.mp3',
        A2: 'A2.mp3',
        C3: 'C3.mp3',
        'D#3': 'Ds3.mp3',
        'F#3': 'Fs3.mp3',
        A3: 'A3.mp3',
        C4: 'C4.mp3',
        'D#4': 'Ds4.mp3',
        'F#4': 'Fs4.mp3',
        A4: 'A4.mp3',
        C5: 'C5.mp3',
        'D#5': 'Ds5.mp3',
        'F#5': 'Fs5.mp3',
        A5: 'A5.mp3',
        C6: 'C6.mp3',
        'D#6': 'Ds6.mp3',
        'F#6': 'Fs6.mp3',
        A6: 'A6.mp3',
        C7: 'C7.mp3',
        'D#7': 'Ds7.mp3',
        'F#7': 'Fs7.mp3',
        A7: 'A7.mp3',
        C8: 'C8.mp3',
      },
      release: 1,
      baseUrl: 'https://tonejs.github.io/audio/salamander/',
    }).toDestination();

    // Reprendre le contexte Audio sur interaction utilisateur
    const resumeAudioContext = async () => {
      if (Tone.context.state !== 'running') {
        await Tone.start();
      }
      window.removeEventListener('click', resumeAudioContext);
      window.removeEventListener('keydown', resumeAudioContext);
    };

    window.addEventListener('click', resumeAudioContext);
    window.addEventListener('keydown', resumeAudioContext);

    // Gestion des collisions
    Matter.Events.on(engine, 'collisionStart', (event) => {
      event.pairs.forEach((pair) => {
        const { bodyA, bodyB, collision } = pair;

        // Détecter la collision entre une boule et une ligne
        if (
          (bodyA.label === 'Ball' && bodyB.label === 'Line') ||
          (bodyA.label === 'Line' && bodyB.label === 'Ball')
        ) {
          const line = bodyA.label === 'Line' ? bodyA : bodyB;

          // Obtenir la longueur de la ligne
          const lineLength = Matter.Vector.magnitude({
            x: line.vertices[1].x - line.vertices[0].x,
            y: line.vertices[1].y - line.vertices[0].y,
          });

          // Définir les notes de piano disponibles
          const notes = [
            'C3',
            'D3',
            'E3',
            'F3',
            'G3',
            'A3',
            'B3',
            'C4',
            'D4',
            'E4',
            'F4',
            'G4',
            'A4',
            'B4',
            'C5',
            'D5',
            'E5',
            'F5',
            'G5',
            'A5',
            'B5',
            'C6',
          ];

          // Calculer l'index de la note en fonction de la longueur de la ligne
          const minLength = 50; // Longueur minimale
          const maxLength = 1000; // Longueur maximale

          const clampedLength = Math.max(
            minLength,
            Math.min(maxLength, lineLength)
          );

          const noteIndex = Math.floor(
            ((maxLength - clampedLength) / (maxLength - minLength)) *
              (notes.length - 1)
          );

          const note = notes[noteIndex];

          // Jouer le son du piano avec Tone.js
          if (synthRef.current) {
            synthRef.current.triggerAttackRelease(note, '8n', Tone.now());
          }
        }

        // Gestion du rebond physique
        if (
          (bodyA.label === 'Ball' || bodyB.label === 'Ball') &&
          (bodyA.isStatic || bodyB.isStatic)
        ) {
          const ball = bodyA.label === 'Ball' ? bodyA : bodyB;

          const normal = collision.normal;
          const velocity = ball.velocity;
          const dotProduct = Matter.Vector.dot(velocity, normal);

          const newVelocity = Matter.Vector.mult(
            Matter.Vector.sub(
              velocity,
              Matter.Vector.mult(normal, 2 * dotProduct)
            ),
            1.8
          );

          Matter.Body.setVelocity(ball, {
            x: newVelocity.x,
            y: newVelocity.y,
          });
        }
      });
    });

    // Dessiner la ligne de prévisualisation après le rendu
    Matter.Events.on(render, 'afterRender', () => {
      const context = render.context;
      if (previewLineRef.current) {
        context.beginPath();
        context.moveTo(
          previewLineRef.current.start.x,
          previewLineRef.current.start.y
        );
        context.lineTo(
          previewLineRef.current.end.x,
          previewLineRef.current.end.y
        );
        context.lineWidth = 5;
        context.strokeStyle = '#aaaaaa';
        context.stroke();
      }
    });

    const handleResize = () => {
      render.canvas.width = window.innerWidth;
      render.canvas.height = window.innerHeight;
      Matter.Body.setPosition(emitter, {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
    };

    window.addEventListener('resize', handleResize);

    // Nettoyage
    return () => {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      Matter.World.clear(engine.world);
      Matter.Engine.clear(engine);
      render.canvas.remove();
      window.removeEventListener('resize', handleResize);
      clearInterval(emissionInterval);
      // Supprimer le synthétiseur
      if (synthRef.current) {
        synthRef.current.dispose();
      }
      window.removeEventListener('click', resumeAudioContext);
      window.removeEventListener('keydown', resumeAudioContext);
    };
  }, []);

  // Gestion du rechargement du jeu
  useEffect(() => {
    if (isReloading) {
      const resetGame = () => {
        const bodies = Matter.Composite.allBodies(engineRef.current.world);
        bodies.forEach((body) => {
          if (body !== emitterRef.current) {
            Matter.World.remove(engineRef.current.world, body);
          }
        });
        setStartPoint(null);
        setIsDragging(false);
        setIsDrawingLine(false);
        previewLineRef.current = null;
        setHighlightedLine(null);

        // Force la mise à jour du rendu
        Matter.Render.stop(renderRef.current);
        Matter.Render.run(renderRef.current);
      };

      resetGame();
      setIsReloading(false);
    }
  }, [isReloading]);

  const handleMouseDown = (event) => {
    const rect = sceneRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (isDeleteToolActive) {
      const mousePoint = { x, y };
      const bodies = Matter.Composite.allBodies(engineRef.current.world);
      const bodiesUnderMouse = Matter.Query.point(bodies, mousePoint);

      const lineUnderMouse = bodiesUnderMouse.find(
        (body) => body.label === 'Line'
      );

      if (lineUnderMouse) {
        Matter.World.remove(engineRef.current.world, lineUnderMouse);
        setHighlightedLine(null);

        // Force la mise à jour du rendu
        Matter.Render.stop(renderRef.current);
        Matter.Render.run(renderRef.current);
      }
    } else {
      if (Matter.Bounds.contains(emitterRef.current.bounds, { x, y })) {
        setIsDragging(true);
      } else {
        setStartPoint({ x, y });
        setIsDrawingLine(true);
      }
    }
  };

  const handleMouseMove = (event) => {
    const rect = sceneRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (isDeleteToolActive) {
      const mousePoint = { x, y };
      const bodies = Matter.Composite.allBodies(engineRef.current.world);
      const bodiesUnderMouse = Matter.Query.point(bodies, mousePoint);

      const lineUnderMouse = bodiesUnderMouse.find(
        (body) => body.label === 'Line'
      );

      if (lineUnderMouse && lineUnderMouse !== highlightedLine) {
        // Réinitialisez la ligne précédemment surlignée
        if (highlightedLine) {
          highlightedLine.render.fillStyle = '#ffffff';
        }
        // Surlignez la nouvelle ligne
        lineUnderMouse.render.fillStyle = '#ff0000';
        setHighlightedLine(lineUnderMouse);

        // Force la mise à jour du rendu
        Matter.Render.stop(renderRef.current);
        Matter.Render.run(renderRef.current);
      } else if (!lineUnderMouse && highlightedLine) {
        // Réinitialisez la ligne surlignée
        highlightedLine.render.fillStyle = '#ffffff';
        setHighlightedLine(null);

        // Force la mise à jour du rendu
        Matter.Render.stop(renderRef.current);
        Matter.Render.run(renderRef.current);
      }
    } else {
      // Réinitialisez la ligne surlignée si l'outil de suppression n'est pas actif
      if (highlightedLine) {
        highlightedLine.render.fillStyle = '#ffffff';
        setHighlightedLine(null);

        // Force la mise à jour du rendu
        Matter.Render.stop(renderRef.current);
        Matter.Render.run(renderRef.current);
      }

      // Gestion du déplacement de l'émetteur et du dessin des lignes
      if (isDragging) {
        Matter.Body.setPosition(emitterRef.current, { x, y });
      } else if (isDrawingLine && startPoint) {
        const currentPoint = { x, y };
        previewLineRef.current = { start: startPoint, end: currentPoint };
      }
    }
  };

  const handleMouseUp = (event) => {
    const rect = sceneRef.current.getBoundingClientRect();
    const endX = event.clientX - rect.left;
    const endY = event.clientY - rect.top;

    if (isDragging) {
      setIsDragging(false);
    } else if (isDrawingLine && startPoint) {
      const length = Math.hypot(endX - startPoint.x, endY - startPoint.y);
      const angle = Math.atan2(endY - startPoint.y, endX - startPoint.x);

      previewLineRef.current = null; // Supprimer la ligne de prévisualisation

      const line = Matter.Bodies.rectangle(
        (startPoint.x + endX) / 2,
        (startPoint.y + endY) / 2,
        length,
        5,
        {
          label: 'Line',
          isStatic: true,
          angle: angle,
          restitution: 1,
          friction: 0,
          collisionFilter: { category: 0x0002, mask: 0x0002 },
          render: { fillStyle: '#ffffff' },
        }
      );

      Matter.World.add(engineRef.current.world, [line]);
      setIsDrawingLine(false);
      setStartPoint(null);
    }
  };

  return (
    <>
      <GameMenu
        isDeleteToolActive={isDeleteToolActive}
        setIsDeleteToolActive={setIsDeleteToolActive}
        isReloading={isReloading}
        setIsReloading={setIsReloading}
      />
      <div
        ref={sceneRef}
        className={styles.GameWindow}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => {
          setIsDragging(false);
          if (isDrawingLine) {
            previewLineRef.current = null;
            setIsDrawingLine(false);
          }
        }}
      ></div>
    </>
  );
};

export default GameWindow;
