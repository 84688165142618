import React, { useEffect, useState } from 'react';
import styles from '../styles/Home.module.scss';
import PlayButton from '../components/PlayButton';

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Vérifie si l'utilisateur est sur un appareil mobile
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile|android|iphone|ipad|tablet/.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  return (
    <div className={styles.homePage}>
      {isMobile ? (
        <p className={styles.warning}>
          Ce site a été conçu pour ordinateur !<br />
          Votre appareil n'est pas compatible...
        </p>
      ) : (
        <>
          <h1 className={styles.title}>Zen Line</h1>
          <PlayButton />
        </>
      )}
      <div className={styles.version}>V 1.0</div>
    </div>
  );
};

export default Home;
