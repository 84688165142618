import React from 'react';
import styles from '../styles/TEST.module.scss';

// import TEST :

const Test = () => {
  return (
    <div className={styles.testPage}>
      <div className={styles.testZone}></div>
    </div>
  );
};

export default Test;
