import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Tone from 'tone';
import styles from '../styles/GameMenu.module.scss';
import BackArrow from '../assets/icons/arrow.png';
import ReloadArrow from '../assets/icons/rotate.png';
import DeleteTool from '../assets/icons/delete.svg';
import DeleteToolActivate from '../assets/icons/deleteActivate.svg';
import notMute from '../assets/icons/sound.svg';
import Mute from '../assets/icons/mute.svg';

const GameMenu = ({
  isDeleteToolActive,
  setIsDeleteToolActive,
  isReloading,
  setIsReloading,
}) => {
  const [isMuted, setIsMuted] = useState(false);
  const [isRotating, setIsRotating] = useState(false); // Nouvel état pour l'animation
  const navigate = useNavigate();

  useEffect(() => {
    Tone.Master.mute = false;
  }, []);

  const handleDeleteToolToggle = () => {
    setIsDeleteToolActive((prevState) => !prevState);
  };

  const handleReload = () => {
    setIsRotating(true); // Démarre l'animation de rotation
    setIsReloading(true); // Déclenche la réinitialisation dans GameWindow
    setTimeout(() => {
      setIsRotating(false); // Arrête l'animation après 1 seconde
    }, 1000);
  };

  const handleMuteToggle = () => {
    setIsMuted((prevState) => {
      const newMutedState = !prevState;
      Tone.Master.mute = newMutedState;
      return newMutedState;
    });
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className={styles.Menu}>
      <img
        src={BackArrow}
        alt="Bouton pour retourner à l'écran d'accueil"
        className={styles.iconsMenu}
        onClick={handleBackClick}
      />
      <img
        src={isDeleteToolActive ? DeleteToolActivate : DeleteTool}
        alt="Bouton pour activer/désactiver l'outil de suppression des lignes"
        className={styles.iconsMenu}
        onClick={handleDeleteToolToggle}
      />
      <img
        src={ReloadArrow}
        alt="Bouton pour redémarrer une partie"
        className={`${styles.iconsMenu} ${isRotating ? styles.rotate : ''}`}
        onClick={handleReload}
      />
      <img
        src={isMuted ? Mute : notMute}
        alt="Bouton pour activer/désactiver le son"
        className={styles.iconsMenu}
        onClick={handleMuteToggle}
      />
    </div>
  );
};

export default GameMenu;
